import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Legal = ({ intl }) => (
  <Layout>
    <SEO lang={intl.locale} title={intl.formatMessage({ id: "legal.title" })} />
    <h1>
      <FormattedMessage id="legal.title" />
    </h1>
    <h3>
      <FormattedMessage id="legal.header.title" />
    </h3>
    <p>
      <FormattedMessage id="legal.header.owner" /><br />
      <FormattedMessage id="legal.header.vatid" /><br />
      <FormattedMessage id="legal.header.email" /><br />
      <FormattedMessage id="legal.header.address" /><br />
      <FormattedMessage id="legal.header.phone" /><br />
    </p>
    <h3>
      <FormattedMessage id="legal.users.title" />
    </h3>
    <p>
      <FormattedMessage id="legal.users.content" />
    </p>
    <h3>
      <FormattedMessage id="legal.website.title" />
    </h3>
    <p>
      <FormattedMessage id="legal.website.content1" />
    </p>
    <p>
      <FormattedMessage id="legal.website.content2" />
    </p>
    <p>
      <FormattedMessage id="legal.website.content3" />
    </p>
    <h3>
      <FormattedMessage id="legal.agreement.title" />
    </h3>
    <p>
      <FormattedMessage id="legal.agreement.content" />
    </p>
    <h3>
      <FormattedMessage id="legal.protection.title" />
    </h3>
    <p>
      <FormattedMessage id="legal.protection.content" />
    </p>
    <h3>
      <FormattedMessage id="legal.ownership.title" />
    </h3>
    <p>
      <FormattedMessage id="legal.ownership.content" />
    </p>
    <h3>
      <FormattedMessage id="legal.photos.title" />
    </h3>
    <p>
      <FormattedMessage id="legal.photos.content1" />
    </p>
    <p>
      <FormattedMessage id="legal.photos.content2" />
    </p>
    <p>
      <FormattedMessage id="legal.photos.content3" />
    </p>
    <h3>
      <FormattedMessage id="legal.disclaimer.title" />
    </h3>
    <p>
      <FormattedMessage id="legal.disclaimer.content1" />
    </p>
    <p>
      <FormattedMessage id="legal.disclaimer.content2" />
    </p>
    <h3>
      <FormattedMessage id="legal.exclusion.title" />
    </h3>
    <p>
      <FormattedMessage id="legal.exclusion.content" />
    </p>
    <h3>
      <FormattedMessage id="legal.modifications.title" />
    </h3>
    <p>
      <FormattedMessage id="legal.modifications.content" />
    </p>
    <h3>
      <FormattedMessage id="legal.law.title" />
    </h3>
    <p>
      <FormattedMessage id="legal.law.content" />
    </p>
    <p>
      <br />
      <FormattedMessage id="legal.version" />
    </p>


  </Layout>
)

export default injectIntl(Legal)
